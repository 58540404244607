import React from "react";
import { hydrate, render } from "react-dom";
import { productionStore } from "store/store";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
if (rootElement!.hasChildNodes()) {
  hydrate(
    <Provider store={productionStore}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={productionStore}>
      <App />
    </Provider>,

    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
