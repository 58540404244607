export const feedUrls = [
  {
    link: "https://feeds.feedburner.com/SmallBusinessTrends?format=xml",
    sourceLink: "https://smallbiztrends.com/",
    searchParams: {
      title: "title",
      link: "link",
      author: "creator",
      pubDate: "pubDate",
      category: "category",
      description: "description",
    },
  },
  {
    link: "https://www.noobpreneur.com/feed/",
    sourceLink: "https://www.noobpreneur.com",
    searchParams: {
      title: "title",
      link: "link",
      author: "creator",
      pubDate: "pubDate",
      category: "category",
      description: "description",
    },
  },
  {
    link: "https://www.smallbusinessbonfire.com/feed/",
    sourceLink: "https://www.smallbusinessbonfire.com",
    searchParams: {
      title: "title",
      link: "link",
      author: "creator",
      pubDate: "pubDate",
      category: "category",
      description: "description",
    },
  },
  {
    link: "https://www.smallbiztechnology.com/feed/",
    sourceLink: "https://www.smallbiztechnology.com",
    searchParams: {
      title: "title",
      link: "link",
      author: "creator",
      pubDate: "pubDate",
      category: "category",
      description: "description",
    },
  },
  {
    link: "https://www.fincyte.com/feed/",
    sourceLink: "https://www.fincyte.com",
    searchParams: {
      title: "title",
      link: "link",
      author: "creator",
      pubDate: "pubDate",
      category: "category",
      description: "description",
    },
  },
  {
    link: "https://www.investors.com/feed/",
    sourceLink: "https://www.investors.com",
    searchParams: {
      title: "title",
      link: "link",
      author: "creator",
      pubDate: "pubDate",
      category: "category",
      description: "description",
    },
  },
];
