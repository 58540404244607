export const capitalizeWord = (word: string) => {
  let hyphen = false;
  return word
    ? word
        .split("")
        .reduce((capitalized: string, letter: string, index: number) => {
          if (index === 0 || hyphen) {
            hyphen = false;
            return capitalized + letter.toUpperCase();
          }
          if (letter === "-") {
            hyphen = true;
            return capitalized + letter;
          }
          return capitalized + letter.toLowerCase();
        }, "")
    : "";
};

export const cleanString = (string: string) => {
  return string
    .replaceAll("&#8217;", "'")
    .replaceAll("&#8230;", "...")
    .replaceAll("&#187;", "-->")
    .replaceAll("</", "")
    .replaceAll("&amp;", "and")
    .replaceAll("&#038;", "&");
};
