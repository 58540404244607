import * as React from "react";
import Typography from "@mui/material/Typography";
import Title from "../MUI/Title";
import { Divider, Grid, Link, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { updateSelectedCategories } from "store/filter/filterSlice";
import { Item } from "components/styled-components";
import { ThemeContext } from "@emotion/react";

type ArticleProps = {
  source?: string;
  sourceLink?: string;
  title?: string;
  category?: string[];
  description?: string;
  author?: string;
  pubDate?: DateTime;
  link: string;
};
export const Article = ({
  title,
  sourceLink,
  category,
  description,
  author,
  source,
  pubDate,
  link,
}: ArticleProps) => {
  const dispatch = useDispatch();

  const categoryClickHandler = (category: string) => {
    console.log(category);
    dispatch(updateSelectedCategories({ category }));
  };
  return (
    <>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        {category &&
          category.map((category, i) => {
            return (
              <Grid item key={description + "-" + i}>
                <Item
                  sx={{ "&:hover": { cursor: "pointer", color: "black" } }}
                  onClick={() => {
                    categoryClickHandler(category);
                  }}
                >
                  {category}
                </Item>
              </Grid>
            );
          })}
      </Grid>

      <Link
        href={link}
        sx={{ color: "black", "&:hover": { color: "primary" } }}
        underline="none"
        target="_blank"
        rel="noreferrer"
      >
        <Typography
          component="p"
          variant="h5"
          sx={{ color: "black", "&:hover": { color: "#3676c8" } }}
        >
          {title}
        </Typography>
      </Link>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        {author} <strong>|</strong>{" "}
        {pubDate?.toLocaleString(DateTime.DATETIME_MED)}
      </Typography>
      <Divider sx={{ paddingBottom: "1rem" }} />

      <Typography sx={{ padding: "1rem 0", color: "black" }}>
        {description}
      </Typography>
      <Link
        href={sourceLink}
        sx={{
          color: "primary",
          "&:hover": { cursor: "pointer", color: "black" },
        }}
        underline="none"
        target="_blank"
        rel="noreferrer"
      >
        <Item sx={{ "&:hover": { cursor: "pointer", color: "black" } }}>
          {source}
        </Item>
      </Link>
    </>
  );
};
