import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterState } from "types";

const initialState: FilterState = {
  selectedSources: [],
  selectedCategories: [],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: initialState,
  reducers: {
    setSelectedCategories(
      state: FilterState,
      action: PayloadAction<{ categories: string[] }>
    ) {
      state.selectedCategories = action.payload.categories;
    },
    setSelectedSources(
      state: FilterState,
      action: PayloadAction<{ sources: string[] }>
    ) {
      state.selectedSources = action.payload.sources;
    },
    updateSelectedCategories(
      state: FilterState,
      action: PayloadAction<{ category: string }>
    ) {
      if (state.selectedCategories.includes(action.payload.category)) {
        state.selectedCategories = state.selectedCategories.filter(
          (category) => category !== action.payload.category
        );
      } else {
        state.selectedCategories.push(action.payload.category);
      }
    },
    updateSelectedSources(
      state: FilterState,
      action: PayloadAction<{ source: string }>
    ) {
      if (state.selectedSources.includes(action.payload.source)) {
        state.selectedSources = state.selectedSources.filter(
          (source) => source !== action.payload.source
        );
      } else {
        state.selectedSources.push(action.payload.source);
      }
    },
  },
});

export const {
  setSelectedCategories,
  setSelectedSources,
  updateSelectedCategories,
  updateSelectedSources,
} = filterSlice.actions;
