import { useState, useEffect, useCallback } from "react";
import { ArticleType } from "types";
import { feedUrls, capitalizeWord, cleanString } from "utils";
import { DateTime } from "luxon";

export const useRSSParser = () => {
  const [articleList, setArticleList] = useState<ArticleType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const addSource = useCallback((source: string | undefined) => {
    if (!source) {
      return;
    }
  }, []);

  const getCategory = useCallback((categoryQuery: NodeList | undefined) => {
    const categories: string[] = [];
    if (categoryQuery) {
      categoryQuery.forEach((category) => {
        const categoryText = category.textContent!;
        const words = categoryText.split(" ");

        const categoryCaseAdjusted = words.reduce((category, word, i) => {
          const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
          return (category +=
            i < words.length - 1 ? capitalized + " " : capitalized);
        }, "");
        categories.push(categoryCaseAdjusted);
      });
      //const category = categoryQuery.split("A[")[1].split("]")[0];
      return categories;
    } else {
      return [];
    }
  }, []);

  const getDescription = useCallback((descriptionQuery: string) => {
    descriptionQuery = descriptionQuery.split("<![CDATA[")[1];
    descriptionQuery = descriptionQuery.split("]]>")[0];
    if (descriptionQuery.includes("<p>")) {
      descriptionQuery = descriptionQuery.split("p>")[1].split("</p")[0];
    }
    if (descriptionQuery.includes("<a")) {
      descriptionQuery = descriptionQuery.split("<a")[0];
    }
    descriptionQuery = cleanString(descriptionQuery);

    return descriptionQuery;
  }, []);

  const getAuthor = useCallback((authorQuery: string) => {
    const author = authorQuery.split("A[")[1].split("]")[0];

    const authorNames = author.split(" ");

    return (
      capitalizeWord(authorNames[0]) + " " + capitalizeWord(authorNames[1])
    );
  }, []);
  const getTitle = useCallback((titleQuery: string) => {
    return cleanString(titleQuery);
  }, []);

  useEffect(() => {
    const getRss = async (
      feedURLs: { link: string; sourceLink: string; searchParams: any }[]
    ) => {
      for (const feed of feedURLs) {
        const urlRegex =
          /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;
        if (!urlRegex.test(feed.link)) {
          return;
        }

        const res = await fetch(
          `https://api.allorigins.win/get?url=${feed.link}`
        );
        const { contents } = await res.json();
        const feedXML = new window.DOMParser().parseFromString(
          contents,
          "text/xml"
        );
        const source = feedXML.querySelector("title")?.innerHTML;
        addSource(source);
        const items = feedXML.querySelectorAll("item");

        const feedItems = [...items].splice(0, 20).map((el) => {
          return {
            source: source,
            source_link: feed.sourceLink,
            link: el.querySelector(feed.searchParams.link)?.innerHTML,
            title: getTitle(
              el.querySelector(feed.searchParams.title)?.innerHTML
            ),
            description: getDescription(
              el.querySelector(feed.searchParams.description)?.innerHTML!
            ),
            author: getAuthor(
              el.querySelector(feed.searchParams.author)?.innerHTML!
            ),
            category: getCategory(
              el.querySelectorAll(feed.searchParams.category)
            ),
            pub_date: DateTime.fromRFC2822(
              el.querySelector(feed.searchParams.pubDate)?.innerHTML!
            ),
          };
        });
        setArticleList((previousArticles) => {
          return [...previousArticles, ...feedItems];
        });
      }
      setLoading(false);
    };
    getRss(feedUrls);
  }, [getAuthor, getDescription, getCategory, addSource]);

  return {
    articles: articleList,
    loading: loading,
  };
};
