import { FilterState } from "types";

export const selectedSourcesSelector = ({
  filterState,
}: {
  filterState: FilterState;
}) => {
  console.log(filterState);
  return filterState.selectedSources;
};
