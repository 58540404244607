import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { Box, Grid, Typography } from "@mui/material";

const drawerWidth: number = 300;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const AdDrawer = ({ adList }: { adList: string[] }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={true} sx={{ marginTop: `4rem` }}>
        <Grid
          container
          gap={4}
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ overflowY: "scroll", height: "calc(100vh - 4rem)" }}
        >
          {adList.map((ad, idx) => {
            return (
              <Grid
                item
                key={`${ad} `}
                sx={{
                  backgroundColor: (theme) => theme.palette.grey[100],
                  height: 250,
                  width: 250,
                  borderRadius: 3,
                  justifyContent: "center",
                  alignItems: "center",
                }}
                alignItems="center"
                xs={10}
              >
                <Typography sx={{ width: "fit-content" }}>Ad {ad}</Typography>
              </Grid>
            );
          })}
        </Grid>
      </Drawer>
    </Box>
  );
};
