import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { Box, Button, Toolbar, Typography } from "@mui/material";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const AppBar = ({
  open,
  setDialogOpen,
}: {
  open?: boolean;
  setDialogOpen: () => void;
}) => {
  return (
    <StyledAppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="28.000000pt"
          height="28.000000pt"
          viewBox="0 0 428.000000 428.000000"
          preserveAspectRatio="xMidYMid meet"
        >
          <g
            transform="translate(0.000000,428.000000) scale(0.100000,-0.100000)"
            fill="#ffffff"
            stroke="#ffffff"
          >
            <path
              stroke="white"
              d="M0 2140 l0 -2140 2140 0 2140 0 0 2140 0 2140 -2140 0 -2140 0 0
-2140z m2685 1888 c17 -9 57 -21 89 -27 32 -6 77 -20 100 -31 22 -10 57 -26
76 -35 55 -25 147 -80 220 -132 46 -33 186 -177 216 -222 104 -157 141 -245
179 -426 26 -121 27 -141 22 -300 -15 -442 -147 -722 -429 -902 -32 -21 -74
-48 -94 -60 -19 -13 -47 -26 -62 -29 -59 -14 -60 -41 -6 -133 18 -31 44 -76
57 -101 13 -25 63 -112 110 -195 47 -82 134 -237 194 -343 59 -106 120 -214
135 -240 15 -26 38 -67 51 -92 13 -25 52 -94 87 -155 35 -60 71 -126 82 -145
11 -19 41 -73 68 -120 65 -113 63 -115 -110 -115 -116 0 -131 2 -149 20 -16
17 -96 159 -386 690 -21 39 -51 93 -66 120 -15 28 -131 238 -258 468 -127 230
-231 425 -231 433 0 32 22 54 69 68 27 8 67 24 88 35 21 12 54 28 73 36 142
63 304 192 374 299 88 133 131 305 130 516 0 264 -72 449 -239 616 -67 67
-186 154 -211 154 -6 0 -17 7 -24 15 -7 8 -37 22 -67 31 -30 9 -76 26 -101 37
-47 22 -52 22 -866 25 -760 3 -820 2 -837 -14 -18 -17 -19 -64 -19 -1775 l0
-1757 -31 -11 c-42 -14 -171 -14 -204 1 l-25 11 0 1892 c0 1478 3 1894 12
1903 10 10 228 12 983 10 881 -3 973 -5 1000 -20z m-295 -522 c48 -8 101 -23
120 -35 19 -11 42 -21 51 -21 9 0 33 -12 53 -27 19 -16 59 -45 86 -67 96 -73
170 -197 194 -326 31 -163 14 -356 -43 -482 -35 -77 -107 -173 -158 -211 -21
-15 -55 -41 -77 -57 -21 -17 -46 -30 -54 -30 -8 0 -34 -11 -56 -24 -31 -18
-73 -28 -166 -40 -69 -9 -141 -20 -160 -26 -21 -6 -190 -10 -413 -10 l-379 0
-29 29 -29 29 0 636 0 635 22 21 c21 20 33 20 487 20 350 0 487 -4 551 -14z
m-146 -1597 c14 -7 31 -28 39 -48 8 -20 34 -77 57 -126 23 -50 54 -117 69
-150 33 -70 74 -159 124 -265 91 -195 123 -262 164 -355 25 -55 51 -111 58
-125 28 -52 275 -586 275 -595 0 -21 -196 -33 -260 -16 -20 5 -37 31 -85 128
-33 67 -68 139 -77 160 -15 34 -44 97 -162 348 -19 39 -37 81 -41 95 -4 14
-15 36 -25 50 -10 14 -21 36 -25 50 -4 14 -18 45 -31 70 -24 47 -66 137 -166
355 -33 72 -71 145 -84 163 l-25 32 -222 -2 -222 -3 -5 -715 -5 -714 -25 -14
c-33 -17 -211 -12 -234 7 -14 12 -16 91 -16 836 l0 822 23 11 c32 15 867 16
901 1z"
            />
            <path
              stroke="#ffffff"
              d="M1633 3278 l-23 -11 0 -417 0 -418 29 -11 c21 -9 110 -11 322 -8
l294 4 86 31 c132 47 190 93 247 193 24 43 35 78 43 140 25 194 -20 315 -154
413 -34 25 -70 38 -222 82 -62 18 -586 19 -622 2z"
            />
          </g>
        </svg>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, marginLeft: "1rem" }}
        >
          Right to Receive
        </Typography>
        <Box>
          <Button
            sx={{ color: "white" }}
            onClick={() => {
              setDialogOpen();
            }}
          >
            Subscribe
          </Button>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};
