import React, { useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { EmailOutlined as EmailOutlinedIcon } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { ThemeContext } from "@emotion/react";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export const SubscribeDialog = (props: SimpleDialogProps) => {
  const { onClose, selectedValue, open } = props;
  const [email, setEmail] = React.useState<string>("");
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsValid(/\S+@\S+\.\S+/.test(email));
    setEmail(event.target.value);
  };
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleSubmit = () => {
    setSubmitted(true);
  };

  useEffect(() => {
    return () => setSubmitted(false);
  }, []);

  useEffect(() => {
    console.log(isValid);
  });

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        "& .MuiTextField-root": { m: 1, width: "35ch" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "70%",
          justifyContent: "space-around",
          p: "2rem",
        }}
      >
        {!submitted ? (
          <>
            <DialogTitle sx={{ textAlign: "center" }}>
              Join <strong>7,600</strong> other professionals starting their day
              with the most up to date alternative finance news.
            </DialogTitle>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <EmailOutlinedIcon sx={{ color: blue[500], mb: 1.5 }} />
              <TextField
                id="standard-basic"
                label="Email"
                variant="standard"
                value={email}
                onChange={handleChange}
              />
            </Box>
            <Button disabled={!isValid} onClick={handleSubmit}>
              Submit
            </Button>
          </>
        ) : (
          <>
            <DialogTitle sx={{ textAlign: "center", pt: "0" }}>
              <strong>Welcome!</strong>
            </DialogTitle>
            <Typography>
              Be sure to check your email for updates on your status!
            </Typography>
          </>
        )}
      </Box>
    </Dialog>
  );
};
