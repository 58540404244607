import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const Item = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  margin: "0.1rem 0.675rem 0.1rem 0",
  component: "h3",
  fontSize: "0.75rem",
  variant: "h6",
  color: theme.palette.primary.dark,
}));
