import React from "react";
import MainView from "./components/main-view/MainView";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactGA from "react-ga4";

ReactGA.initialize("G-9CX6Q62JY5");
ReactGA.send("pageview");
function App() {
  const mdTheme = createTheme();

  return (
    <ThemeProvider theme={mdTheme}>
      <MainView />
    </ThemeProvider>
  );
}

export default App;
