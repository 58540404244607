import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Source as SourceIcon,
  Category as CategoryIcon,
} from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  updateSelectedCategories,
  updateSelectedSources,
} from "store/filter/filterSlice";
const drawerWidth: number = 300;

const ResponsiveDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      top: "4rem",
      zIndex: theme.zIndex.drawer + 2,
    },
  },
}));

export const NavDrawer = ({
  open,
  toggleDrawer,
  categories,
  sources,
  selectedSources,
  selectedCategories,
}: {
  open?: boolean;
  toggleDrawer: () => void;
  authors: string[];
  categories: string[];
  sources: string[];
  selectedSources: string[];
  selectedCategories: string[];
}) => {
  const dispatch = useDispatch();

  return (
    <Box sx={{ display: "flex" }}>
      <ResponsiveDrawer
        variant="permanent"
        open={open}
        sx={{
          marginTop: `4rem`,
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav" dense>
          <ListItem>
            <ListItemIcon>
              <SourceIcon />
            </ListItemIcon>
            <ListItemText>Sources</ListItemText>
          </ListItem>
          <List
            sx={{
              height: "12rem",
              overflowY: "scroll",
            }}
            dense
          >
            {open &&
              sources.sort().map((source, idx) => {
                return (
                  <ListItemButton
                    key={`${source}${idx}`}
                    onClick={() =>
                      dispatch(updateSelectedSources({ source: source }))
                    }
                    sx={{
                      backgroundColor: selectedSources.includes(source)
                        ? "#f5f5f5"
                        : "none",
                    }}
                  >
                    <ListItemText sx={{ margin: 0, whiteSpace: "nowrap" }}>
                      <Typography
                        sx={{ fontSize: "0.875rem", whiteSpace: "nowrap" }}
                      >
                        {source}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
          </List>
          <Divider sx={{ my: 1 }} />

          <ListItem>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <ListItemText>Categories</ListItemText>
          </ListItem>
          <List
            sx={{
              height: "12rem",
              overflowY: "scroll",
              width: "100%",
            }}
            dense
          >
            {open &&
              categories.sort().map((category, idx) => {
                return (
                  <ListItemButton
                    key={`${category}${idx}`}
                    onClick={() =>
                      dispatch(updateSelectedCategories({ category: category }))
                    }
                    sx={{
                      backgroundColor: selectedCategories.includes(category)
                        ? "#f5f5f5"
                        : "none",
                      height: "2rem",
                    }}
                  >
                    <ListItemText sx={{ margin: 0, whiteSpace: "normal" }}>
                      <Typography
                        sx={{ fontSize: "0.875rem", whiteSpace: "normal" }}
                      >
                        {category}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
          </List>
        </List>
      </ResponsiveDrawer>
    </Box>
  );
};
