import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  Box,
  Toolbar,
  Container,
  Grid,
  Paper,
  Link,
} from "@mui/material";

import { Article } from "./Article";
import { useRSSParser } from "hooks";
import { Copyright } from "./Copyright";
import { NavDrawer } from "./NavDrawer";
import { ArticleType } from "types";
import { AppBar } from "components/AppBar";
import { AdDrawer } from "./AdDrawer";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { selectedSourcesSelector } from "store/filter/selectors/selectedSourcesSelector";
import { selectedCategoriesSelector } from "store/filter/selectors/selectedCategoriesSelector";
import { SubscribeDialog } from "components/SubscribeDialog";
const emails = ["username@gmail.com", "user02@gmail.com"];
const MainViewContent = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { articles, loading } = useRSSParser();
  const [selectedArticles, setSelectedArticles] =
    useState<ArticleType[]>(articles);

  const [authorList, setAuthorList] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [sourceList, setSourceList] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [localTimeZone, setLocalTimeZone] = useState<string>("");
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const dispatch = useDispatch();
  const selectedSources = useSelector(selectedSourcesSelector);
  const selectedCategories = useSelector(selectedCategoriesSelector);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = (value: string) => {
    setDialogOpen(false);
  };
  const adList: string[] = [];
  for (let i = 0; i < 20; i++) {
    adList.push(`${i + 1}`);
  }

  useEffect(() => {
    if (!loading) {
      setSelectedArticles(articles);
    }
  }, [articles, loading]);

  useEffect(() => {
    const authors: string[] = [];
    const categories: string[] = [];
    const sources: string[] = [];
    articles.forEach((article) => {
      if (!authors.includes(article.author!)) {
        authors.push(article.author!);
      }
      if (!sources.includes(article.source!)) {
        sources.push(article.source!);
      }
      article.category!.forEach((category) => {
        if (!categories.includes(category)) {
          categories.push(category);
        }
      });
    });
    setAuthorList(authors);
    setCategoryList(categories);
    setSourceList(sources);
  }, [articles]);

  useEffect(() => {
    setSelectedArticles(
      articles
        .filter((article) => {
          if (selectedSources.length === 0) {
            return true;
          } else {
            return selectedSources.includes(article.source!);
          }
        })
        .filter((article) => {
          if (selectedCategories.length === 0) {
            return true;
          } else {
            const found = selectedCategories.reduce((found, category) => {
              return found ? article.category.includes(category) : false;
            }, true);
            return found;
          }
        })
    );
  }, [selectedCategories, selectedSources, articles]);

  useEffect(() => {
    const dt = DateTime.local();
    const tz = dt.zoneName;
    setLocalTimeZone(tz);
  }, []);
  useEffect(() => {
    console.log(dialogOpen);
  });

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar open={open} setDialogOpen={handleClickOpen} />
      <NavDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        authors={authorList}
        categories={categoryList}
        sources={sourceList}
        selectedCategories={selectedCategories}
        selectedSources={selectedSources}
      />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {selectedArticles
              .sort((a1: ArticleType, a2: ArticleType) => {
                return a1.pub_date! < a2.pub_date! ? 1 : -1;
              })
              .map((item, idx) => {
                return (
                  <Grid item xs={12} md={12} lg={12} key={idx}>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.001)",
                          boxShadow: "1px 3px 3px rgba(0,0,0, 0.5)",
                          transition: "all 0.2s ease-in-out",
                        },
                      }}
                    >
                      <Article
                        source={item.source}
                        sourceLink={item.source_link}
                        title={item.title}
                        author={item.author}
                        category={item.category}
                        description={item.description}
                        pubDate={item.pub_date}
                        link={item.link!}
                      />
                    </Paper>
                  </Grid>
                );
              })}
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
      {false && <AdDrawer adList={adList} />}
      <SubscribeDialog
        open={dialogOpen}
        onClose={handleClose}
        selectedValue={selectedValue}
      />
    </Box>
  );
};

export default function MainView() {
  return <MainViewContent />;
}
